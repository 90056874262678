import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { getCookie } from 'typescript-cookie';
import { TourService } from 'ngx-ui-tour-md-menu';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'classroom-studio';
  constructor(private router: Router, private tourService: TourService) {}

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const userLocation = localStorage.getItem('user_location');
        const access_token = getCookie('access_token');
        const tacStatus = localStorage.getItem('tac_status');
        if (access_token && !tacStatus) {
          this.router.navigateByUrl('/auth/terms-and-conditions');
        } else if (access_token && !userLocation) {
          this.router.navigateByUrl('/auth/create-profile');
          return;
        }
        // if (!access_token && ) {
        //   this.router.navigateByUrl('/auth/login');
        //   return;
        // }
      });
  }
}
